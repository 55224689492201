var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-10" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "9" } },
            [
              _vm.fetchingUserInfo || _vm.fetchingOrgs
                ? _c("full-screen-loader")
                : _vm.troubleshootList
                ? _c(
                    "div",
                    _vm._l(_vm.troubleshootList, function (help) {
                      return _c(
                        "v-alert",
                        {
                          key: help.reqid,
                          attrs: { type: "warning", text: "", prominent: "" },
                        },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(help.msg) },
                          }),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }